// Import Core Clean Blog SCSS
@import "../assets/vendor/startbootstrap-clean-blog/scss/clean-blog.scss";
@import "navbar.scss";
@import "syntax.scss";
@import "toc.scss";

// Our css
body {
  color: var(--bs-body-color);
}
.post-preview{
  a {
    color: var(--bs-body-color);
  }
}

// Profile card

.profile-card {
  z-index: 2;
  overflow: hidden;
  -webkit-box-shadow:
    0px 3px 6px rgba(0, 0, 0, 0.16),
    0px 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow:
    0px 3px 6px rgba(0, 0, 0, 0.16),
    0px 3px 6px rgba(0, 0, 0, 0.23);
  width: auto;
  height: auto;
  background: var(--bs-body-bg);
  border-radius: 4px;
  text-align: center;
  margin-bottom: 1rem;

  @media (min-width: 1420px) {
    width: 280px;
    transform: translate(-40%, -50%);
  }

  h1 {
    font-size: unset;
  }

  h2 {
    font-size: unset;
  }

  header {
    width: inherit;
    height: 250px;
    padding: 10px 20px 10px 20px;
    display: inline-block;
    background: var(--bs-body-bg);;
    color: var(--bs-body-color);;
    text-align: center;

    h1 {
      color: #ff5722;
    }

    a {
      display: inline-block;
      text-align: center;
      position: relative;
      margin: 25px 30px;

      > img {
        width: 120px;
        max-width: 100%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -webkit-transition: -webkit-box-shadow 0.3s ease;
        transition: box-shadow 0.3s ease;
        -webkit-box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.06);
        box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.06);
      }
    }

    a:hover > img {
      -webkit-box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
    }
  }

  .profile-bio {
    width: inherit;
    height: auto;
    display: block;
    background: var(--bs-body-bg);;
    color: var(--bs-body-color);;
    text-align: center;
    p {
      margin: 0px 10px;
    }
  }

  .profile-social-links {
    height: 40px;
    display: block;
    padding: 15px 20px;
    background: var(--bs-body-bg);;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    display: table;
    margin-bottom: 0px;

    li {
      list-style: none;
      margin: -5px 0px 0px 0px;
      padding: 0px;
      text-align: center;
      display: table-cell;

      a {
        display: inline-block;
        color: red;
        width: 24px;
        position: relative;
        overflow: hidden !important;

        i {
          position: relative;
          z-index: 1;
        }

        img,
        svg {
          width: 24px;
        }
      }
    }
  }
}

.team-presentation .profile-card {
  margin: 1rem;
  @media (min-width: 1420px) {
    width: 280px;
    transform: translate(0%, 0%);
  }
}

// Image override

.img-fill {
  max-width: 100%;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.highlight-word {
  color: $primary;
  font-style: italic;
}

.partners {
  text-align: center;
  .partner-logo {
    width: 100px;
    margin: 20px;
  }
}

p {
  a {
    color: #007bff;
    text-decoration: none;
  }
}

li {
  a {
    color: #007bff;
    text-decoration: none;
  }
}

.tags {
  margin-top: 20px;
  .tag {
    color: white;
    padding: 4px 8px;
    text-align: center;
    border: 2px solid white;
    border-radius: 5px;
    text-decoration: none;
  }
}

.masthead {
  .container {
    position: inherit;
    z-index: 1; 
  }
}

.post-preview {
  a {
    text-decoration: none;
  }
}

@import "copy-from-codeblocks.scss";

[data-bs-theme="dark"] {
  --docsearch-primary-color: var(--bs-body-color);
  --docsearch-text-color: var(--bs-body-color);
  --docsearch-searchbox-background: var(--bs-body-bg);
  --docsearch-modal-background: var(--bs-body-bg);
  --docsearch-footer-background: var(--bs-body-bg);
  --docsearch-hit-background: var(--bs-body-bg);
  --docsearch-hit-active-color: var(--bs-emphasis-color);
  --docsearch-highlight-color: var(--bs-secondary-color);
  --docsearch-searchbox-focus-background: var(--bs-body-bg);
  --docsearch-hit-color: var(--bs-body-color);
  --docsearch-key-gradient: linear-gradient(-225deg,#d5dbe4,#f8f8f8);
  --docsearch-hit-shadow: var(--bs-box-shadow);
  --docsearch-searchbox-shadow: 0rem 0.2rem 0.2rem rgba(128, 128, 128, 0.2);

}
