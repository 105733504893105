html[data-bs-theme="light"] {
  pre.highlight {
    padding: 5px;
  }
  .highlight .hll {
    background-color: #ffffcc;
  }
  .highlight {
    background: #f8f8f8;
    border-radius: 5px;
  }
  .highlight .c {
    color: #408080;
    font-style: italic;
  } /* Comment */
  .highlight .err {
    border: 1px solid #ff0000;
  } /* Error */
  .highlight .k {
    color: #008000;
    font-weight: bold;
  } /* Keyword */
  .highlight .o {
    color: #666666;
  } /* Operator */
  .highlight .ch {
    color: #408080;
    font-style: italic;
  } /* Comment.Hashbang */
  .highlight .cm {
    color: #408080;
    font-style: italic;
  } /* Comment.Multiline */
  .highlight .cp {
    color: #bc7a00;
  } /* Comment.Preproc */
  .highlight .cpf {
    color: #408080;
    font-style: italic;
  } /* Comment.PreprocFile */
  .highlight .c1 {
    color: #408080;
    font-style: italic;
  } /* Comment.Single */
  .highlight .cs {
    color: #408080;
    font-style: italic;
  } /* Comment.Special */
  .highlight .gd {
    color: #a00000;
  } /* Generic.Deleted */
  .highlight .ge {
    font-style: italic;
  } /* Generic.Emph */
  .highlight .gr {
    color: #ff0000;
  } /* Generic.Error */
  .highlight .gh {
    color: #000080;
    font-weight: bold;
  } /* Generic.Heading */
  .highlight .gi {
    color: #00a000;
  } /* Generic.Inserted */
  .highlight .go {
    color: #888888;
  } /* Generic.Output */
  .highlight .gp {
    color: #000080;
    font-weight: bold;
  } /* Generic.Prompt */
  .highlight .gs {
    font-weight: bold;
  } /* Generic.Strong */
  .highlight .gu {
    color: #800080;
    font-weight: bold;
  } /* Generic.Subheading */
  .highlight .gt {
    color: #0044dd;
  } /* Generic.Traceback */
  .highlight .kc {
    color: #008000;
    font-weight: bold;
  } /* Keyword.Constant */
  .highlight .kd {
    color: #008000;
    font-weight: bold;
  } /* Keyword.Declaration */
  .highlight .kn {
    color: #008000;
    font-weight: bold;
  } /* Keyword.Namespace */
  .highlight .kp {
    color: #008000;
  } /* Keyword.Pseudo */
  .highlight .kr {
    color: #008000;
    font-weight: bold;
  } /* Keyword.Reserved */
  .highlight .kt {
    color: #b00040;
  } /* Keyword.Type */
  .highlight .m {
    color: #666666;
  } /* Literal.Number */
  .highlight .s {
    color: #ba2121;
  } /* Literal.String */
  .highlight .na {
    color: #7d9029;
  } /* Name.Attribute */
  .highlight .nb {
    color: #008000;
  } /* Name.Builtin */
  .highlight .nc {
    color: #0000ff;
    font-weight: bold;
  } /* Name.Class */
  .highlight .no {
    color: #880000;
  } /* Name.Constant */
  .highlight .nd {
    color: #aa22ff;
  } /* Name.Decorator */
  .highlight .ni {
    color: #999999;
    font-weight: bold;
  } /* Name.Entity */
  .highlight .ne {
    color: #d2413a;
    font-weight: bold;
  } /* Name.Exception */
  .highlight .nf {
    color: #0000ff;
  } /* Name.Function */
  .highlight .nl {
    color: #a0a000;
  } /* Name.Label */
  .highlight .nn {
    color: #0000ff;
    font-weight: bold;
  } /* Name.Namespace */
  .highlight .nt {
    color: #008000;
    font-weight: bold;
  } /* Name.Tag */
  .highlight .nv {
    color: #19177c;
  } /* Name.Variable */
  .highlight .ow {
    color: #aa22ff;
    font-weight: bold;
  } /* Operator.Word */
  .highlight .w {
    color: #bbbbbb;
  } /* Text.Whitespace */
  .highlight .mb {
    color: #666666;
  } /* Literal.Number.Bin */
  .highlight .mf {
    color: #666666;
  } /* Literal.Number.Float */
  .highlight .mh {
    color: #666666;
  } /* Literal.Number.Hex */
  .highlight .mi {
    color: #666666;
  } /* Literal.Number.Integer */
  .highlight .mo {
    color: #666666;
  } /* Literal.Number.Oct */
  .highlight .sa {
    color: #ba2121;
  } /* Literal.String.Affix */
  .highlight .sb {
    color: #ba2121;
  } /* Literal.String.Backtick */
  .highlight .sc {
    color: #ba2121;
  } /* Literal.String.Char */
  .highlight .dl {
    color: #ba2121;
  } /* Literal.String.Delimiter */
  .highlight .sd {
    color: #ba2121;
    font-style: italic;
  } /* Literal.String.Doc */
  .highlight .s2 {
    color: #ba2121;
  } /* Literal.String.Double */
  .highlight .se {
    color: #bb6622;
    font-weight: bold;
  } /* Literal.String.Escape */
  .highlight .sh {
    color: #ba2121;
  } /* Literal.String.Heredoc */
  .highlight .si {
    color: #bb6688;
    font-weight: bold;
  } /* Literal.String.Interpol */
  .highlight .sx {
    color: #008000;
  } /* Literal.String.Other */
  .highlight .sr {
    color: #bb6688;
  } /* Literal.String.Regex */
  .highlight .s1 {
    color: #ba2121;
  } /* Literal.String.Single */
  .highlight .ss {
    color: #19177c;
  } /* Literal.String.Symbol */
  .highlight .bp {
    color: #008000;
  } /* Name.Builtin.Pseudo */
  .highlight .fm {
    color: #0000ff;
  } /* Name.Function.Magic */
  .highlight .vc {
    color: #19177c;
  } /* Name.Variable.Class */
  .highlight .vg {
    color: #19177c;
  } /* Name.Variable.Global */
  .highlight .vi {
    color: #19177c;
  } /* Name.Variable.Instance */
  .highlight .vm {
    color: #19177c;
  } /* Name.Variable.Magic */
  .highlight .il {
    color: #666666;
  } /* Literal.Number.Integer.Long */
}

html[data-bs-theme="dark"] {
  $dt-gray-dark: #282a36; // Background
  $dt-gray: #44475a; // Current Line & Selection
  $dt-gray-light: #f8f8f2; // Foreground
  $dt-blue: #6272a4; // Comment
  $dt-cyan: #8be9fd;
  $dt-green: #50fa7b;
  $dt-orange: #ffb86c;
  $dt-pink: #ff79c6;
  $dt-purple: #bd93f9;
  $dt-red: #ff5555;
  $dt-yellow: #f1fa8c;

  /*
 * Styles
 */

  .highlight {
    background: $dt-gray-dark;
    color: $dt-gray-light;

    .hll,
    .s,
    .sa,
    .sb,
    .sc,
    .dl,
    .sd,
    .s2,
    .se,
    .sh,
    .si,
    .sx,
    .sr,
    .s1,
    .ss {
      color: $dt-yellow;
    }

    .go {
      color: $dt-gray;
    }

    .err,
    .g,
    .l,
    .n,
    .x,
    .p,
    .ge,
    .gr,
    .gh,
    .gi,
    .gp,
    .gs,
    .gu,
    .gt,
    .ld,
    .no,
    .nd,
    .ni,
    .ne,
    .nn,
    .nx,
    .py,
    .w,
    .bp {
      color: $dt-gray-light;
    }

    .gh,
    .gi,
    .gu {
      font-weight: bold;
    }

    .ge {
      text-decoration: underline;
    }

    .bp {
      font-style: italic;
    }

    .c,
    .ch,
    .cm,
    .cpf,
    .c1,
    .cs {
      color: $dt-blue;
    }

    .kd,
    .kt,
    .nb,
    .nl,
    .nv,
    .vc,
    .vg,
    .vi,
    .vm {
      color: $dt-cyan;
    }

    .kd,
    .nb,
    .nl,
    .nv,
    .vc,
    .vg,
    .vi,
    .vm {
      font-style: italic;
    }

    .na,
    .nc,
    .nf,
    .fm {
      color: $dt-green;
    }

    .k,
    .o,
    .cp,
    .kc,
    .kn,
    .kp,
    .kr,
    .nt,
    .ow {
      color: $dt-pink;
    }

    .m,
    .mb,
    .mf,
    .mh,
    .mi,
    .mo,
    .il {
      color: $dt-purple;
    }

    .gd {
      color: $dt-red;
    }
  }
}
