.section-nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    color: var(--bs-secondary-color) !important;
    position: sticky;
    top: 5rem;
    right: 0;
    z-index: 2;
    height: calc(100vh - 7rem);
    overflow-y: auto;

    a {
        display: block;
        padding: .125rem 0 .125rem .75rem;
        color: inherit;
        text-decoration: none;
        border-left: .125rem solid transparent;
    }
    ul {
        list-style: none;
    }

    a.active {
        font-weight: 500;
    }
    a:hover, a.active {
    color: var(--bs-body-color);
    border-left-color: var(--bd-toc-color);
    }
}