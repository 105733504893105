div.highlight {
  position: relative;
}

pre.highlight {
  padding: 16px;
}

pre.highlight > button {
  opacity: 0;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  
  padding: 6px;
  background-color: var(--bs-body-bg);
  border-radius: 4px;
  border: 0px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

}

pre.highlight:hover > button {
  opacity: 1;
}

pre.highlight > button:active,
pre.highlight > button:focus {
  outline: none;
}
